import React from "react";
import Navigation from "./Navigation";

function Header() {
  return (
    <>
            <Navigation />
    </>
  );
}

export default Header;
