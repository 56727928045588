import React from "react";

function Video  () {

return(
<>
    <iframe
      width="550"
      height="310"
      src="https://www.youtube.com/embed/jszWvmznL1M"
      frameborder="0"
    /> 
    </>
);
}

export default Video ;
